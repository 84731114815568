import $ from 'jquery'
import Spinner from '@/shared/spinner.vue'
import { dateToAppFormat } from '@/utils'

export default {
  components: { Spinner },
  props: {
    locationId: {
      type: Number
    },
    size: {
      type: String
    },
    zip: {
      type: String
    }
  },
  created() {
    if ((this.locationId == null) && (this.zip == null) && (this.size == null))
      console.error("locationId or size and zip is required")
    //const status = $(this.statusField).val()
  },
  data() {
    return {
      availabilities: [],
      loading: false,
      start: dateToAppFormat(Date.today().setTimeToNow())
    }
  },
  computed: {
    params() {
      let dt = new Date();
      let diffTZ = dt.getTimezoneOffset() * -1;
      var params = {
        date: this.start,
        size: this.size,
        ofs: diffTZ
      }
      if (this.zip) {
        params.zip = this.zip
      }
      if (this.locationId) {
        params.location_id = this.locationId
      }
      return params
    },
  },
  methods: {
    loadAvailabilities() {
      this.loading = true
      this.availabilities = []
      $.get({
        type: 'GET',
        url: '/availabilities',
        data: this.params,
        success: (data) => {
          this.availabilities = [data.slice(0, 3), data.slice(3, 6), data.slice(6)]
          this.loading = false
        }
      })
    },
    reloadAvailabilities(date) {
      this.start = date
      this.loadAvailabilities()
    },
  },
  mounted() {
    this.loadAvailabilities()
  },
  watch: {
    start(newValue, oldValue) {
      if (this.mode == 'hours')
        this.loadAvailabilities()
    },
  }
}
